<template>
  <div>
    <div class="card" style="display: flex">
      <div class="left">
        <el-button
          type="primary"
          icon="el-icon-plus"
          @click="dialogVisible = true"
          >新增供应商</el-button
        >
      </div>
      <div class="line"></div>
      <div class="middle">
        <el-form :model="formInline" :inline="true">
          <el-form-item label="供应商名称">
            <el-input
              placeholder="请输入供应商名称"
              v-model="formInline.supplier_name"
              clearable
              @keydown.enter.native="onSubmit"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">搜索</el-button>
          </el-form-item>
          <el-form-item>
            <el-button @click="resetForm">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="table">
      <el-table
        :data="tableData.list"
        style="width: 100%"
        v-loading="loading"
        height="700"
        stripe
      >
        <template v-for="item in rowList">
          <el-table-column
            :key="item.field_alias"
            :prop="item.field_alias"
            :label="item.field_text"
            align="center"
            :width="item.t_width"
          >
            <template slot-scope="scope">
              <span v-if="item.field_alias === 'tax_rate'">
                {{ scope.row[item.field_alias] + "%" }}
              </span>
              <span v-else>
                {{
                  scope.row[item.field_alias] ||
                  scope.row[item.field_alias] === 0
                    ? scope.row[item.field_alias]
                    : "--"
                }}
              </span>
            </template>
          </el-table-column>
        </template>
        <template>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="handleEdit(scope.row)"
                >修改</el-button
              >
              <el-button
                type="text"
                @click="handleDele(scope.row)"
                style="color: #f56c6c"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <div class="pagina">
      <el-pagination
        background
        :current-page="page"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="$store.state.pageSizes"
        :page-size="limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.total"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="form.supplier_id ? '编辑供应商' : '新增供应商'"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-form :model="form" label-width="100px">
        <el-form-item label="供应商名称" required>
          <el-input v-model="form.supplier_name"></el-input>
        </el-form-item>
        <el-form-item label="税率(%)">
          <el-input v-model="form.tax_rate"></el-input>
        </el-form-item>
        <el-form-item label="联系人">
          <el-input v-model="form.contacts"></el-input>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input v-model="form.contact_phone"></el-input>
        </el-form-item>
        <el-form-item label="地址">
          <el-input v-model="form.address"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" v-model="form.remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSubmit" :loading="saveLoading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getListReq, createReq, delReq } from "@/api/system/supplier";

export default {
  data() {
    return {
      supplier_name: "",
      saveLoading: false,
      dialogVisible: false,
      page: 1,
      limit: this.$store.state.pageSizes[0],
      loading: false,
      formInline: {},
      form: {},
      tableData: {
        list: [],
        total: 0,
      },
      rowList: [
        { field_text: "序号", field_alias: "index" },
        { field_text: "供应商", field_alias: "supplier_name" },
        { field_text: "税率(%)", field_alias: "tax_rate" },
        { field_text: "入库", field_alias: "num" },
        { field_text: "在库", field_alias: "stock_text",width:300 },
        { field_text: "联系人", field_alias: "contacts" },
        { field_text: "联系电话", field_alias: "contact_phone" },
        { field_text: "地址", field_alias: "address" },
        { field_text: "创建时间", field_alias: "create_time" },
        { field_text: "备注", field_alias: "remark" },
      ],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    resetForm() {
      this.formInline = {};
      this.page = 1;
      // this.limit = 15;
      this.getList();
    },
    handleSubmit() {
      this.saveLoading = true;
      createReq({ ...this.form })
        .then((res) => {
          if (res.code === 1) {
            this.saveLoading = false;
            this.$message.success("操作成功");
            this.handleClose();
            this.getList();
          }
        })
        .catch(() => {
          this.saveLoading = false;
        });
    },
    handleEdit(row) {
      this.form = { ...row };
      this.dialogVisible = true;
    },
    handleDele(row) {
      this.$confirm("确定要删除该供应商吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delReq({
            supplier_id: row.supplier_id,
          }).then((res) => {
            if (res.code === 1) {
              this.$message({
                type: "success",
                message: "删除成功",
              });
              this.getList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    onSubmit() {
      this.page = 1;
      this.getList();
    },
    handleClose() {
      this.dialogVisible = false;
      this.form = {};
    },
    getList() {
      this.loading = true;
      getListReq({ ...this.formInline, page: this.page, limit: this.limit })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            res.data.list.forEach((item, index) => {
              item.index = index + 1;
            });
            this.tableData = res.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleSizeChange(limit) {
      this.page = 1;
      this.limit = limit;
      this.getList();
    },
    handleCurrentChange(page) {
      this.page = page;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.middle {
  /deep/ .el-form-item {
    margin-bottom: 0px;
  }
}
</style>
