import { post } from '@/utils/request';

// 列表
const getListReq = (params) => post({
  url: '/store/goods.configuration.Supplier/list',
  data: {
    ...params,
  },
});

// 保存
const createReq = (params) => post({
  url: '/store/goods.configuration.Supplier/create',
  data: {
    ...params,
  },
});

// 删除
const delReq = (params) => post({
  url: '/store/goods.configuration.Supplier/del',
  data: {
    ...params,
  },
});

export {
  getListReq,
  createReq,
  delReq,
};
